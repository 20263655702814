import { Controller } from 'react-hook-form';

import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
// @mui
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import Iconify from '../iconify';

// ----------------------------------------------------------------------

export function RHFSelectMenu({
  name,
  native,
  maxHeight = 220,
  helperText,
  children,
  control,
  list,
  PaperPropsSx,
  isIcon = false,
  ...other
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          value={value ?? ''}
          onChange={(event) => onChange(event?.target?.value)}
          select
          fullWidth
          SelectProps={{
            native,
            MenuProps: {
              PaperProps: {
                sx: {
                  ...(!native && {
                    maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                  }),
                  ...PaperPropsSx,
                },
              },
            },
            sx: { textTransform: 'capitalize' },
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        >
          {list?.map((item) => (
            <MenuItem key={item?.value} value={item?.value}>
              {isIcon ? (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon={item?.icon} />
                  <Typography>{item.label}</Typography>
                </Stack>
              ) : item.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
}
