import axios from 'src/utils/axios';

class AuthApi {
  async login(data = {}) {
    const response = await axios.post('/user/client_session', data);
    return response;
  }

  async getLoginToken(data = {}) {
    return await axios.put('/user/client_session', data);
  }

  async register(data = {}, token = '') {
    return await axios.post('/lead_management/api/affiliates', data, {
      headers: {
        Authorization: token,
      },
    });
  }

  async forgotPassword(data = {}, token = '') {
    return await axios.put('/client/password_recovery', data);
  }

  async getUser() {
    const response = await axios.get('/client/client_account/client_account');
    return response;
  }

  async updateUser(data = {}) {
    return await axios.put('/client/client_account/client_account', data);
  }

  async deleteUser() {
    const response = await axios.delete('/client/client_account/client_account');
    return response;
  }
}

export const authApi = new AuthApi();
