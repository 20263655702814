import axios from 'src/utils/axios';

class FormsApi {
  async getForm(form_id) {
    const response = await axios.get(`/client/client_account/forms/${form_id}`);
    return response;
  }
  
  async submitForm(data = {}) {
    const response = await axios.post('/client/client_account/forms', data);
    return response;
  }
}

export const formsApi = new FormsApi();
