import axios from 'axios';

import { getBaseApiUrl } from 'src/config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: getBaseApiUrl() });

axiosInstance.interceptors.request.use(
  config => {
    Object.entries(config.params || {})
      .filter(([_, value]) => !value)
      .forEach(([key]) => {
        delete config.params[key];
      });
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle response errors
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error('Response error:', error.response.data);
      console.error('Status code:', error.response.status);
      if (error?.response?.status === 401) {
        window.location.replace(`${window.location.origin  }/login`);
        localStorage.clear();
        localStorage.clear();
        console.error('error 401');
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an error
      console.error('Error:', error.message);
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error && error.message) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
