import { Tooltip, IconButton } from '@mui/material';

import { RouterLink } from 'src/routes/components';

import useDomainInfo from 'src/hooks/use-domain-info';

import Iconify from 'src/components/iconify';

export const HomeButton = () => {
  const { domainData } = useDomainInfo();

  return (
    <Tooltip
      title="Home"
    >
      <IconButton
        LinkComponent={RouterLink}
        href={`https://${domainData?.internal_brand?.dashboard_url}/login?token=${localStorage.getItem('accessToken')}`}
        target={domainData?.internal_brand?.app_redirect ? "_blank" : ""}
        sx={{
          width: 38,
          height: 38,
          borderRadius: 1,
        }}
      >
        <Iconify icon="bxs:home" width={38} color="primary.main" />
      </IconButton>
    </Tooltip>
  );
};
