import { useState, useEffect } from 'react';

import useDomainInfo from './use-domain-info';

export const useLocalDomainInfo = () => {
  const [domainInfo, setDomainInfo] = useState(null);

  const { domainData } = useDomainInfo();

  useEffect(() => {
    const domainInfoLocal = localStorage.getItem('domain_data')
      ? JSON.parse(localStorage.getItem('domain_data'))
      : null;

    if (domainInfoLocal) {
      setDomainInfo(domainInfoLocal);
    } else {
      setDomainInfo(domainData);
    }
  }, [domainData]);

  return { domainData: domainInfo };
};
