import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { useLocalDomainInfo } from 'src/hooks/use-local-domain-info';

import { useTranslate } from 'src/locales';

// import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  trading_terminal: icon('ic_analytics'),
  trading_history: icon('ic_history'),
  notifications: icon('ic_notifications'),
  wallet: icon('ic_wallet'),
  settings: icon('ic_settings'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate();

  const { domainData } = useLocalDomainInfo();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: "OVERVIEW",
        items: [
          {
            title: t('trading_terminal'),
            path: paths.dashboard.root,
            icon: ICONS.trading_terminal,
          },
          {
            title: t('trading_history'),
            path: paths.dashboard.trading_history,
            icon: ICONS.trading_history,
          },
          {
            title: t('wallet'),
            path: paths.dashboard.wallet,
            icon: ICONS.wallet,
          },
          // {
          //   title: t('notifications'),
          //   path: paths.dashboard.notifications,
          //   icon: ICONS.notifications,
          // },
          {
            title: t('dashboard'),
            path: `https://${domainData?.internal_brand?.dashboard_url}/login?token=${localStorage.getItem('accessToken')}`,
            icon: ICONS.dashboard,
            isTargetBlank: domainData?.internal_brand?.app_redirect
          },
          {
            title: t('settings'),
            path: paths.dashboard.user.account,
            icon: <Iconify icon="solar:settings-bold-duotone" />,
          },
        ],
      },
    ],
    [t, domainData]
  );

  return data;
}
