import { useEffect } from 'react'

import { getAPIUrl } from '../config';
import { useSettingsContext } from '../components/settings';
import { useLocalDomainInfo } from './use-local-domain-info';

export const useGetFavicon = () => {
  const { domainData } = useLocalDomainInfo();
  const settings = useSettingsContext();

  useEffect(() => {
    if (domainData) {
      document.title = `${domainData?.internal_brand?.company_name}`;

      const fav = document.querySelectorAll('.custom_fav');
      fav.forEach((currentFav) => {
        if ((domainData?.colorTheme??settings.themeMode) === 'light') {
          currentFav.setAttribute('href', `${getAPIUrl()}/${domainData?.internal_brand?.trader_white_logo}`);
        } else {
          currentFav.setAttribute('href', `${getAPIUrl()}/${domainData?.internal_brand?.trader_dark_logo}`);
        }
      });
    }
  }, [domainData, settings?.themeMode]);
}

