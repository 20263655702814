import useSWR from 'swr';
import { useMemo } from 'react';

import axios, { fetcher } from 'src/utils/axios';

class FinanceApi {
  async getBalance(params = {}) {
    return await axios.get('/client/client_account/finance/balance', { params });
  }

  async getWallets(params = {}) {
    return await axios.get('/client/client_account/finance/wallet/wallets', { params });
  }

  async getWithdraw(data = {}) {
    return await axios.post(`/client/client_account/finance/withdraw`, data);
  }

  async sendDeposit(request = {}) {
    return await axios.post('/client/client_account/finance/payment_requests', request);
  }

  async getTransactions(params = {}) {
    return await axios.get('/client/client_account/finance/transactions', { params });
  }

  async addFavoriteTicker(data = {}) {
    return await axios.post('/client/client_account/finance/favorite_tickers', data);
  }

  async removeFavoriteTicker(id) {
    return await axios.delete(`/client/client_account/finance/favorite_tickers/${id}`);
  }

  async getPositions(params = {}) {
    return await axios.get(`/client/client_account/finance/positions`, { params });
  }

  async createPosition(data) {
    return await axios.post(`/client/client_account/finance/positions`, data);
  }

  async updatePosition(id, data) {
    return await axios.put(`/client/client_account/finance/positions/${id}`, data);
  }

  async copyPosition(data) {
    return await axios.post(`/client/client_account/finance/copy_positions`, data);
  }

  async getTickers(params = {}) {
    return await axios.get("/client/client_account/finance/tickers", { params });
  }

  async getTradingAccounts(params = {}) {
    return await axios.get("/client/client_account/finance/trading_accounts", { params });
  }

  async createTradingAccount(data = {}) {
    return await axios.post("/client/client_account/finance/trading_accounts", data);
  }

  async updateTradingAccount(id, data = {}) {
    return await axios.put(`/client/client_account/finance/trading_accounts/${id}`, data);
  }
}

export const financeApi = new FinanceApi();

export function useGetTickersList(params) {
  const URL = ["/client/client_account/finance/tickers", { params }] ?? '';

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, { refreshInterval: 500 });

  const memoizedValue = useMemo(
    () => ({
      tickers: data?.tickers || [],
      isLoading,
      error,
      mutate,
      isValidating,
      totalCount: data?.total_count ?? 0,
      totalPage: data?.total_pages ?? 0,
      tickersEmpty: !isLoading && !data?.tickers.length,
    }),
    [data?.tickers, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetBalance() {
  const URL = "/client/client_account/finance/positions";

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, { refreshInterval: 2000 });

  const memoizedValue = useMemo(
    () => ({
      balance: data?.balance,
      isLoading,
      error,
      isValidating,
    }),
    [data?.balance, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetTicker(tickerId, params) {
  const URL = tickerId ? [`/client/client_account/finance/tickers/${tickerId}`, { params }] ?? '' : "";

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      ticker: data?.ticker,
      isLoading,
      error,
      mutate,
      isValidating,
    }),
    [data?.ticker, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetChartData({ ticker, ticker_id, interval = 1, start_date, end_date }, { refreshInterval }) {
  const defaultURL = `/client/client_account/tickers?ticker=${ticker}&interval=${interval}&ticker_id=${ticker_id}`;
  const startURL = start_date ? `&start_date=${start_date}` : "";
  const endURL = end_date ? `&end_date=${end_date}` : "";
  const URL = (ticker && ticker_id) ? defaultURL + startURL + endURL : null;

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, { refreshInterval, refreshWhenHidden: true });

  const memoizedValue = useMemo(
    () => ({
      data,
      isLoading,
      error,
      mutate,
      isValidating,
    }),
    [data?.ticker, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export async function getChartDataByTicker(ticker, ticker_id, interval = 1, start_date, end_date, ticker_only = true) {
  const defaultURL = `/client/client_account/tickers?ticker=${ticker}&interval=${interval}&ticker_id=${ticker_id}`;
  const startURL = start_date ? `&start_date=${start_date}` : "";
  const endURL = end_date ? `&end_date=${end_date}` : "";
  const tickerOnly = ticker_only ? `&ticker_only=true` : "";
  const URL = ( defaultURL + startURL + endURL + tickerOnly);
  const response = (ticker && ticker_id) ? await axios.get(URL) : null;
  return response;
}

export function useGetChartDataInterval({ ticker, interval = 1, start_date, end_date }) {
  const defaultURL = `/client/client_account/tickers?ticker=${ticker}&interval=${interval}`;
  const startURL = start_date ? `&start_date=${start_date}` : "";
  const endURL = end_date ? `&end_date${end_date}` : "";
  const URL = defaultURL + startURL + endURL;

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, { refreshInterval: 6000 });

  const memoizedValue = useMemo(
    () => ({
      data,
      isLoading,
      error,
      mutate,
      isValidating,
    }),
    [data?.ticker, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetPositions(params) {
  const URL = ["/client/client_account/finance/positions", { params }] ?? '';

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, { refreshInterval: 3000 });

  const memoizedValue = useMemo(
    () => ({
      positions: data?.positions,
      balance: data?.balance,
      totalCount: data?.total_count,
      isLoading,
      error,
      mutate,
      isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}


