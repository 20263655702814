// ----------------------------------------------------------------------

export function typography(theme) {
  return {
    MuiTypography: {
      defaultProps: {
        component: 'span',
      },
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  };
}
