import PropTypes from 'prop-types';
import { useMemo, useState, useEffect, forwardRef } from 'react';

// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { usePathname } from 'src/routes/hooks';
// routes
import { RouterLink } from 'src/routes/components';

import { useLocalDomainInfo } from 'src/hooks/use-local-domain-info';

import { getAPIUrl } from 'src/config';

import { useSettingsContext } from '../settings';
// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const { domainData } = useLocalDomainInfo();

  const domainInfoLocal = localStorage.getItem('domain_data')
  ? JSON.parse(localStorage.getItem('domain_data'))
  : null;
  
  const settings = useSettingsContext();

  const pathname = usePathname();

  const getDomain = (input) => {
    const parts = input?.split('.');
    if (parts?.length >= 2) {
      return parts.slice(-2).join('.');
    }
    return input;
  };

  const [isValid, setIsValid] = useState(false);
  const checkImageValidity = () => {
    const img = new Image();
    if (settings?.themeMode === 'light') {
      img.src = domainInfoLocal?.internal_brand?.trader_white_logo;
    } else {
      img.src = domainInfoLocal?.internal_brand?.trader_dark_logo;
    }

    img.onload = () => {
      setIsValid(true);
    };

    img.onerror = () => {
      setIsValid(false);
    };
  };

  useEffect(() => {
    if (domainInfoLocal) {
      checkImageValidity();
    }
  }, [domainInfoLocal, settings?.themeMode]);

  const isLoginOrRegister = useMemo(() => {
    if (['/login', '/register'].includes(pathname)) {
      return true;
    }

    return false;
  }, [pathname]);

  // eslint-disable-next-line consistent-return
  const domainLogo = useMemo(() => {
    if (domainInfoLocal) {
      if (settings?.themeMode === 'light') {
        return domainInfoLocal?.internal_brand?.trader_white_logo;
      } 
      return domainInfoLocal?.internal_brand?.trader_dark_logo;
    }
  }, [domainInfoLocal, settings?.themeMode]);

  const logo = useMemo(()=> (
    <Box
      component="img"
      src={domainLogo ? domainLogo?.includes('http') ? domainLogo : `${getAPIUrl()}/${domainLogo}` : ""}
      sx={{ 
          maxWidth: 80, 
          maxHeight: 45,
          borderRadius: 1, 
          overflow: 'hidden', 
          cursor: 'pointer', 
          ...sx 
        }}
    />
    ), [domainLogo, isValid]);

  if (disabledLink && isValid) {
    return logo;
  }

  return domainLogo ? (
    <Link
      component={RouterLink}
      href={isLoginOrRegister ? `https://${getDomain(domainData?.domain)}/` : '/dashboard'}
      sx={{ display: 'contents' }}
    >
      {logo}
    </Link>
  ) : null;
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
