import { lazy } from 'react';

import { GuestGuard } from 'src/auth/guard';
import AuthClassicLayout from 'src/layouts/auth/classic';


// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/jwt/forgot-password'));


export const authRoutes = [
  {
    path: 'login',
    element: (
      <GuestGuard>
        <AuthClassicLayout>
          <JwtLoginPage />
        </AuthClassicLayout>
      </GuestGuard>
    ),
  },
  {
    path: 'register',
    element: (
      <GuestGuard>
        <AuthClassicLayout>
          <JwtRegisterPage />
        </AuthClassicLayout>
      </GuestGuard>
    ),
  },
  {
    path: 'forgot-password',
    element: (
      <GuestGuard>
        <AuthClassicLayout>
          <ForgotPasswordPage />
        </AuthClassicLayout>
      </GuestGuard>
    ),
  },
];
