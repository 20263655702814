import { useState, useEffect } from 'react';

import axios from 'src/utils/axios';

const useDomainInfo = () => {
  const [domainData, setDomainData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDomain = async () => {
      setIsLoading(true);
      const domain = window.location.host;
      const res = await axios.get(
        `/company/trader_domain?domain=${domain === 'localhost:3030' ? 'live.octolit.com' : domain}`
      );
      const setting = JSON.parse(res?.data?.trader_domain?.internal_brand?.theme_setting);
      let result = res?.data?.trader_domain;
      if (setting) {
        result = {
          ...result,
          colorPreset: setting?.color_preset,
          colorTheme: setting?.color_theme,
        };
      }
      setDomainData(result);
      setIsLoading(false);
    };

    fetchDomain();
  }, []);

  return { domainData, companyId: domainData?.company_id, isDomainLoading: isLoading };
};

export default useDomainInfo;
