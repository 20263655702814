import { useMemo, useState, useEffect } from 'react';

import { alpha } from '@mui/system';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { Chip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { paths } from 'src/routes/paths';
import { useRouter, usePathname } from 'src/routes/hooks';

import useDomainInfo from 'src/hooks/use-domain-info';

import { authApi } from 'src/api/auth';
import { useAuthContext } from 'src/auth/hooks';

import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';

import { HomeButton } from './home-button';

const currencyToIcon = {
  1: 'openmoji:flag-united-states',
  2: 'flag:eu-4x3',
  3: 'fxemoji:greatbritainflag',
  4: 'openmoji:flag-canada',
  5: 'openmoji:flag-australia',
};

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  trading_terminal: icon('ic_analytics'),
  trading_history: icon('ic_history'),
  // notifications: icon('ic_notifications'),
  wallet: icon('ic_wallet'),
  settings: icon('ic_settings'),
};

export const useGetUser = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getUser = async () => {
    setIsLoading(true);
    const res = await authApi.getUser();
    setUser(res?.data?.client);
    setIsLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  return {
    user,
    isLoading,
  };
};

export default function MiniNavMenu() {
  const router = useRouter();
  const pathname = usePathname();
  const settings = useSettingsContext();
  const { user, isLoading } = useGetUser();
  const { domainData } = useDomainInfo();
  const { tradingAccount, user: currentUser, setAccount, accounts } = useAuthContext();

  const handleGoTrade = () => router.push(paths.dashboard.root);

  const handleGoWallet = () => router.push(paths.dashboard.wallet);

  const handleGoNotifications = () => router.push(paths.dashboard.notifications);

  const handleGoAccount = () => router.push(paths.dashboard.user.account);

  useEffect(() => {
    if (!tradingAccount && !localStorage.getItem("trading_account")) {
      if (accounts?.length > 0) {
        setAccount(accounts?.[0]?.id);
      }
    }
  }, [accounts, tradingAccount, setAccount]);

  const iconButtonList = [
    {
      label: 'Trading Terminal',
      icon: ICONS.trading_terminal,
      path: '/dashboard',
      action: () => handleGoTrade(),
      enabled: true,
    },
    {
      label: 'Wallet',
      value: '',
      icon: ICONS.wallet,
      path: '/dashboard/wallet',
      action: () => handleGoWallet(),
      enabled: domainData?.internal_brand?.enabled_wallet,
    },
    // {
    //   label: 'Notifications',
    //   value: '',
    //   icon: ICONS.notifications,
    //   path: '/dashboard/notifications',
    //   action: () => handleGoNotifications(),
    // },
    {
      label: 'Settings',
      value: '',
      icon: ICONS.settings,
      action: () => handleGoAccount(),
      enabled: true,
    },
  ];

  const traderAccount = useMemo(() => {
    if (tradingAccount) {
      return accounts?.find(acc => acc?.id == tradingAccount)?.name;
    } 
      return accounts?.find(acc => acc?.id == localStorage.getItem("trading_account"))?.name ?? ""
    
  }, [tradingAccount, accounts]);

  const traderCurrency = useMemo(() => {
    if (tradingAccount) {
      return accounts?.find(acc => acc?.id == tradingAccount)?.currency;
    } 
      return accounts?.find(acc => acc?.id == localStorage.getItem("trading_account"))?.currency ?? user?.trader_currency;
    
  }, [tradingAccount, accounts, user]);

  return (
    <Stack direction="row" spacing={1}>
      {traderAccount ? (
        <Tooltip title="Trader Account" sx={{ alignSelf: "center" }}>
          <Chip label={(
            <Stack direction="row" alignItems="center" spacing={1}>
              <Iconify icon={currencyToIcon[traderCurrency]} />
              <Typography>{traderAccount}</Typography>
            </Stack>
          )} color='primary' />
        </Tooltip>
       ) : null}
      <HomeButton />
      {isLoading ? (
        null
      ) : (
        iconButtonList?.map(
          (item, index) =>
            item?.enabled && (
              <Tooltip key={index} title={item?.label}>
                <IconButton
                  sx={{
                    backgroundColor:
                      pathname === item?.path
                        ? (theme) => alpha(theme.palette.primary.main, 0.08)
                        : '',
                    color: pathname === item?.path ? 'primary.main' : '',
                    width: 38,
                    height: 38,
                    borderRadius: 1,
                  }}
                  onClick={item?.action}
                >
                  {item?.icon}
                </IconButton>
              </Tooltip>
            )
        )
      )}
    </Stack>
  );
}
